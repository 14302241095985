<template>
	<div class="scence-info" v-if="scenceInfo">
		<div class="scence-title">{{ scenceInfo.siteName }}</div>
		<div class="scence-img">
			<van-image
				width="100%"
				height="100%"
				:src="scenceInfo.sitePicture"
			/>
		</div>
		<div class="scence-desc">
			<div class="scence-descinfo">
				<van-icon name="eye-o" color="#1989fa" /><span class="deseinfo"
					>场景简介：</span
				><span class="info">{{ scenceInfo.siteIntroduction }}</span>
			</div>
		<!--	<div class="scence-descinfo">
				<van-icon name="fire-o" color="#1989fa" /><span class="deseinfo"
					>场景热度：</span
				><span class="info">{{ scenceInfo.siteHeat }}</span>
			</div> -->
			<div class="scence-descinfo">
				<van-icon name="user-circle-o" color="#1989fa" /><span
					class="deseinfo"
					>限制人数：</span
				><span class="info">{{
					scenceInfo.siteLimitNumber > 0
						? scenceInfo.siteLimitNumber
						: '暂无限制'
				}}</span>
			</div>
			<div class="scence-descinfo">
				<van-icon name="location-o" color="#1989fa" /><span
					class="deseinfo"
					>场景地址：</span
				><span class="info">{{ scenceInfo.siteAddress }}</span>
			</div>
		</div>
		<div class="course">
			<van-divider
				:style="{
					color: '#1989fa',
					borderColor: '#1989fa',
					padding: '0 16px',
				}"
			>
				课程推荐
			</van-divider>
			<van-grid
				:column-num="2"
				icon-size="80px"
				v-if="scenceCourse.length > 0"
			>
				<van-grid-item
					v-for="(item, index) in scenceCourse"
					:key="index"
					:text="item.courseName"
					center="true"
					:to="'/mobile/courseInfo?courseId=' + item.courseId"
				>
					<template #icon>
						<van-image
							width="100"
							height="100"
							fit="cover"
							:src="item.coursePicture"
						/>
					</template>
				</van-grid-item>
			</van-grid>
			<van-empty v-else description="暂无课程推荐" />
		</div>
		<div v-html="scenceInfo.siteDetail"></div>
	</div>
</template>
<script>
import { sessionGet } from '@/utils/local'
import { getSceneInfo_api, getSceneList_api } from '@/api/scene'
export default {
	data() {
		return {
			scenceInfo: null, //场景详情
			scenceCourse: null, //场景的相关课程列表
		}
	},
	methods: {
		//获取场景详情
		async getScenceInfo() {
			let { data, code, msg } = await getSceneInfo_api({
				siteId: this.$route.query.id,
			})
			if (code == 200) {
				this.scenceInfo = data
			}
		},
	},
	mounted() {
        this.scenceCourse=this.$store.state.siteCourse
		// this.scenceCourse = JSON.parse(this.$route.query.siteCourse)
		this.getScenceInfo()
	},
}
</script>
<style lang="less" scoped>
.scence-info {
	width: 100vw;
	.scence-title {
		height: 5vh;
		line-height: 5vh;
		text-align: center;
		font-weight: bolder;
	}
	.scence-img {
		margin: 2%;
		border-radius: 10px;
		overflow: hidden;
	}
	.scence-desc {
		margin: 2%;
		.scence-descinfo {
			padding: 1%;
			display: grid;
			grid-template-columns: 5vw 25vw auto;
			.deseinfo {
				font-weight: bold;
				font-size: 90%;
			}
			.info {
				line-height: 120%;
				font-size: 90%;
			}
		}
	}
	.course {
		margin-bottom: 5%;
	}
}
</style>
